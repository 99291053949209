<template>

    <div class="user">
        <h4>更换手机号</h4>
        <div class="editinfo">
            <div class="e1">
                <div>
                    <div class="text"><p>新手机号</p><span>*</span></div>
                    <input type="text" v-model="form.phone" :maxlength="11">
                    <span class="yzm" @click="toyzm">{{ time }}</span>
                </div>
                 <div>
                    <div class="text"><p>手机验证码</p><span>*</span></div>
                    <input type="text"  v-model="form.phone_code">
                </div>
            </div>
            <div class="e1">
                <div>
                    <div class="text"><p>校验码</p><span>*</span></div>
                    <input type="text"  v-model="yzm">
                    <div>
                          <div style="margin-left:20px" @click="makeCode(0, 4)">
                            <yzm :identifyCode="identifyCode" />
                          </div>
                    </div>
                </div>
                 
            </div>
            
        </div>


        <div class="but">
            <div class="edit" @click="tourl2">
                取消
            </div>
            <div class="editpass" @click="upnewphone">
               保存
            </div>
        </div>
    </div>

</template>

<script>
import { getyzmAPI} from "@/api/login";
import { userEditAPI} from "@/api/user";
import yzm from "@/components/yzm";
export default {
  components: {
    yzm
  },

  data () {
    return {
      sign: 1,
      form:{
          phone:"",
          phone_code:"",
      },
       identifyCodes: "1234567890",
      identifyCode: "",
      yzm:"",
       time: "发送验证码",

    }
  },
  created () {
      this.makeCode(0,4)
  },
  methods: {
     tourl2 () {
      this.$router.push({ path: '/user/personinfo' })
    },
    upnewphone(){
      if (this.form.phone=="") {
        this.$message("手机号必填")
        return
      }
       if (this.form.phone_code=="") {
        this.$message("手机验证码必填")
        return
      }
        if (this.identifyCode==this.yzm) {
            userEditAPI(this.form).then(res=>{
                this.$message("修改成功")
                this.tourl2()
            })
        } else {
            this.$message("图形验证码错误")
        }
        
    },
    toyzm() {
      if (this.form.phone == "") {
        this.$message({
          message: "请输入手机号",
          type: "error"
        });
      } else {
        if (this.time == "发送验证码") {
          var that = this;
          that.time = 60;
          if (that.time > 0) {
            var interval = setInterval(function() {
              that.time--;
              if (that.time == 0) {
                clearInterval(interval);
                that.time = "发送验证码";
              }
            }, 1000);
          }
          getyzmAPI({ phone: this.form.phone }).then(res => {
            this.$message({
              message: "验证码短信已发送",
              type: "success"
            });
          });
        } else {
          this.$message({
            message: "请耐心等待",
            type: "warning"
          });
        }
      }
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      this.identifyCode = "";
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ];
      }
      console.log(this.identifyCode);
    },
  }
}
</script>

<style lang="scss" scoped="scoped">

	.user{
		padding: 5px 20px 20px;
		background: #ffffff;
        min-height: 500px;
		h4{
			font-size: 18px;
			font-weight: 500;
			color: #333333;
			font-weight: 500;
			line-height: 45px;
			text-indent: 0;
		}
        .editinfo{
           .e1{
               display: flex;
               margin: 15px 0;
               >div{
                   display: flex;
                   align-items: center;
                    margin-right:20px;
                   .text{
                       display: flex;
                       p{
                           width: 80px;
                            text-align: justify;
                            text-align-last: justify;
                        }
                        span{
                                color: #ff7c00;
                        }
                   }
                   input{
                        width: 240px;
                        height: 40px;
                       margin-left: 20px;
                        background: #f8f8f8;
                        border-radius: 4px;
                        text-indent: 15px;
                   }
                  .yzm{
                        width: 110px;
                        height: 40px;
                        background: #FFF1E5;
                        border-radius: 4px;
                        margin-left: 20px;
                        color: #ff7c00;
                        text-align: center;
                        line-height: 40px;
                        font-size: 14px;
                        cursor: pointer;
                  }
                   
               }
           }
           

        }
       
        
        
        .but{
            display: flex;
            margin-top: 50px;
            div{
                width: 150px;
                height: 45px;
                border-radius: 4px;
                font-size: 18px;
                font-weight: 500;
                text-align: center;
               
                line-height: 45px;
            }
            .edit{
                 color: #999999;
                border: 1px solid #999999;
                margin-right: 30px;
            }
            .editpass{
                color: #fff;
                background: #ff7c00;
                border: 1px solid #ff7c00;
            }
        }
		
	}

</style>
